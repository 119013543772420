/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import { graphql } from 'gatsby';
import sortBy from 'lodash.sortby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
    sectionsFilters,
    sectionsStyles,
} from '../../../content/productsFilters';
import withLocation from '../../providers/withLocation';
import Gallery from '../components/Gallery';
import SEO from '../components/SEO';
import ToolBar from '../components/ToolBar';
import Hero from '../layouts/Hero';
import MainLayout from '../layouts/MainLayout';
import Container from '../components/Container';

const ProductGalleryTemplate = ({
    data,
    location,
    pageContext: { breadcrumb, locale, productSection, translations },
    search,
}) => {
    const list = data.products.edges.map((n) => n.node);
    const sortedList = sortBy(list, ['fields.section', 'titre']);
    const [gallery, setGallery] = useState(sortedList);

    const updateList = () => {
        const recursive_filter = (list, arrayFilters, index = 0) => {
            if (arrayFilters.length === 0) {
                return list;
            }
            if (index === arrayFilters.length - 1) {
                return list.filter(arrayFilters[index]);
            }
            return recursive_filter(
                list.filter(arrayFilters[index]),
                arrayFilters,
                index + 1,
            );
        };
        const updateList = sortedList;
        const searchList = Object.keys(search);
        const arrFilters = [];
        searchList.forEach((section) => {
            arrFilters.push((t) => t[section] === search[section]);
        });
        const test = recursive_filter(updateList, arrFilters);
        setGallery(test);
    };

    useEffect(() => {
        if (Object.entries(search).length !== 0) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // Update list if location.search has changed

    const filtersBase = [
        {
            label: 'categories',
            link: true,
            list: data.filtersBase.group,
        },
    ];

    const allFilters =
        Object.entries(search).length !== 0
            ? [
                  {
                      active: Object.entries(search).length !== 0,
                      label: 'Selection',
                      link: false,
                      list: [
                          {
                              fieldValue: 'TOTAL',
                              totalCount:
                                  Object.entries(search).length !== 0
                                      ? gallery.length
                                      : 0,
                          },
                      ],
                      noClick: true,
                  },
                  ...filtersBase,
              ]
            : [...filtersBase];

    sectionsFilters[productSection].map((filter) => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.products.hasOwnProperty(filter)) {
            allFilters.push({
                label: filter,
                link: false,
                list: data.products[filter],
            });
        }
    });

    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            filters={allFilters}
            neverTransparent={sectionsStyles[productSection].neverTransparent}
        >
            <SEO
                title={`product.pages.${productSection}.headerTitle`}
                description={`product.pages.${productSection}.description`}
                location={location}
                translationsPaths={translations}
            />
            <Hero
                image={data.hero.childImageSharp.gatsbyImageData}
                colors={data.hero.fields.colors}
                title={`product.pages.${productSection}.hero.h1`}
                text1={`product.pages.${productSection}.hero.p1`}
                text2={
                    productSection === 'cards'
                        ? `product.pages.${productSection}.hero.p2`
                        : null
                }
                textColorDark={sectionsStyles[productSection].textColorDark}
                titleColorDark={sectionsStyles[productSection].titleColorDark}
            />
            <main id='content'>
                <ToolBar crumbs={breadcrumb.crumbs} hasFilter />
                <Container>
                    <Gallery cards={gallery} />
                </Container>
            </main>
        </MainLayout>
    );
};

ProductGalleryTemplate.propTypes = {
    data: PropTypes.shape({
        filtersBase: PropTypes.object.isRequired,
        hero: PropTypes.object.isRequired,
        products: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        breadcrumb: PropTypes.object.isRequired,
        filters: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
        productSection: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
    search: PropTypes.object.isRequired,
};

export default withLocation(ProductGalleryTemplate);

export const pageQuery = graphql`
    query ($locale: String!, $productSection: String!) {
        hero: file(
            sourceInstanceName: { eq: "hero" }
            name: { eq: $productSection }
        ) {
            ...BgImages
        }
        products: allProducts(
            filter: {
                fields: { section: { eq: $productSection } }
                lang: { eq: $locale }
            }
        ) {
            edges {
                node {
                    ...ProductCard
                    ...ProductFilters
                }
            }
            segment: group(field: segment) {
                ...ProductFiltersCount
            }
            format: group(field: format) {
                ...ProductFiltersCount
            }
            type: group(field: type) {
                ...ProductFiltersCount
            }
            collection: group(field: collection) {
                ...ProductFiltersCount
            }
            couleur: group(field: couleur) {
                ...ProductFiltersCount
            }
        }
        filtersBase: allProducts(filter: { lang: { eq: $locale } }) {
            group(field: fields___section) {
                totalCount
                fieldValue
            }
        }
    }
`;
