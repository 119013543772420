const common = ['couleur', 'collection', 'type'];

const sectionsFilters = {
    calendars: ['format'],
    cards: ['segment', 'format'],
    items: common,
    stationery: common,
};

const sectionsStyles = {
    calendars: {
        neverTransparent: true,
        textColorDark: false,
        titleColorDark: false,
    },
    cards: {
        neverTransparent: false,
        textColorDark: false,
        titleColorDark: false,
    },
    items: {
        neverTransparent: true,
        textColorDark: true,
        titleColorDark: true,
    },
    stationery: {
        neverTransparent: false,
        textColorDark: false,
        titleColorDark: false,
    },
};

module.exports = { sectionsFilters, sectionsStyles };
